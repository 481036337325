import React from 'react'
import ExternalLink from '../assets/icons/external-link.svg'

const TableEntryVendor = ({ data }) => {
  const generateEntry = (data) => {
    if (data === 'Yes') {
      return <p className='entry entry-success'>Yes</p> }
    else if (data === 'No') {
      return <p className='entry entry-warning'>No</p> }
    else if (data === 'Automatic') {
      return <p className='entry entry-info'>Automatic</p> }
    else if (data === 'API') {
      return <p className='entry entry-success'>{`Yes-${data}`}</p> }
    else if (data === 'Scraping') {
      return <p className='entry entry-success'>{`Yes-${data}`}</p> }
    else if (data === 'In development') {
      return <p className='entry entry-neutral'>{data}</p> }
    else if (data === 'N/A') {
      return <p className='entry entry-neutral'>{data}</p> }
    else {
      return <p className='entry entry-info'>{data}</p> }
  }

  return (
    <div className='vendor-table-entry' data-sal='slide-up' data-sal-easing='ease' data-sal-delay='100' data-sal-duration='800'>
      <div><img className='entry-logo' src={require(`../assets/icons/platform/round/${data.title}.svg`)}/></div>
      <div>{generateEntry(data.fetchProducts)}</div>
      <div>{generateEntry(data.inventorySync)}</div>
      <div>{generateEntry(data.createOrders)}</div>
      <div>{generateEntry(data.fetchFulfillments)}</div>
      <div>{generateEntry(data.importCancellations)}</div>
      <div>{generateEntry(data.exportCancellations)}</div>
      <div>{generateEntry(data.taxOnPayouts)}</div>
      <div>{generateEntry(data.fetchPerformance)}</div>
      <div><a href={data.support} className='external-link' target='_blank'><img src={ExternalLink} alt='link'/></a></div>
    </div>
  )
}

export default TableEntryVendor   
                        
                        
                        
                        
                        